import ButtonBase from "@material-ui/core/ButtonBase"
import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  LaatsteBerichtenDataFragment,
  PageBuilderActiviteitLaatsteBerichtenFragment,
  PageBuilderErvaringLaatsteBerichtenFragment,
  PageBuilderLaatsteBerichtenFragment,
  PageBuilderNieuwsLaatsteBerichtenFragment,
  PageBuilderVacatureLaatsteBerichtenFragment,
} from "../../__generated__/graphql-gatsby"

interface IProps {
  block:
    | PageBuilderLaatsteBerichtenFragment
    | PageBuilderErvaringLaatsteBerichtenFragment
    | PageBuilderActiviteitLaatsteBerichtenFragment
    | PageBuilderNieuwsLaatsteBerichtenFragment
    | PageBuilderVacatureLaatsteBerichtenFragment
}

const Wrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
`

const Button = styled(props => <ButtonBase {...props} component={Link} />)`
  position: relative;
  width: 100%;
  height: auto;
  max-width: 362px;
  margin: ${({ theme }) => `${theme.spacing(4)}px`};

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: ${({ theme }) => theme.transitions.create("background-color")};
  }

  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`

const BG = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) =>
    `${theme.spacing(2.5)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
`

type GetExcerpt = (node: LaatsteBerichtenDataFragment) => string
const getExcerpt: GetExcerpt = node => {
  if ("excerpt" in node) {
    return node.excerpt ? node.excerpt : ""
  }

  return ""
}

const Latest: React.FC<IProps> = ({ block }) => {
  const { type, aantal } = block

  const amount = aantal ? aantal : 3
  const nodes = type?.nodesOfPostTypes?.nodes?.slice(0, amount) || []

  return (
    <Wrapper maxWidth="lg" disableGutters>
      {nodes.map(node =>
        node ? (
          <Button key={node.id} to={String(node.uri)}>
            <BG elevation={3}>
              <Typography variant="h6" component="p" color="primary">
                {node.title}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                dangerouslySetInnerHTML={{ __html: getExcerpt(node) }}
              />
              <Typography variant="subtitle2" align="right">
                Lees meer...
              </Typography>
            </BG>
          </Button>
        ) : null
      )}
    </Wrapper>
  )
}

export default Latest
